import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    sensorgroup: [],
    loginuser: null,
    selectdevice: null
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setSensorGroup(state, param) {
      state.sensorgroup = param
    },
    setLoginUser(state, param) {
      state.loginuser = param
    },
    setDevice(state, param) {
      state.selectdevice = param
    }
  },
  actions: {
  },
  modules: {
  }
})
