import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index.js'

import { AmplifyEventBus } from 'aws-amplify-vue'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import {getUser} from '../js/auth.js'

import Login from '../views/Login.vue'

Vue.use(VueRouter)
Vue.use(AmplifyPlugin, AmplifyModules)

let user

getUser().then((user) => {
  if (user) {
    store.commit('setUser', user);
    router.push({path: '/lastdata'});
  }else{
    store.commit('setUser', null);
  }
})

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut'){
    user = null
    store.commit('setUser', null)
    router.push({path: '/'}, () => {})
  } else if (state === 'signedIn') {
    user = await getUser();
    router.push({path: '/lastdata'})
  }
})

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/lastdata',
    name: 'lastdata',
    component: () => import('../views/Lastdata.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/data',
    name: 'data',
    component: () => import('../views/Data.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/Userconfirm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/Setting.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/Data.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/userdev',
    name: 'userdev',
    component: () => import('../views/Userdev.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/sensorgroup',
    name: 'sensorgroup',
    component: () => import('../views/Sensorgroup.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/public/:value',
    name: 'public',
    component: () => import('../views/Public.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/public2/:value',
    name: 'public',
    component: () => import('../views/Public2.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/userpassword',
    name: 'userpassword',
    component: () => import('../views/UserPassword.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/passwordreset',
    name: 'passwordreset',
    component: () => import('../views/PasswordReset.vue'),
    meta: { requiresAuth: false }
  },
  // {
  //   path: '*',
  //   name: 'NotFound',
  //   component: () => import('../views/NotFound.vue'),
  // }
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/Information.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: process.env.VUE_APP_REDIRECT_PATH,
    name: 'external',
    beforeEnter() {
      window.location = process.env.VUE_APP_REDIRECT_URL
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  user = await getUser();
  if (!user) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      return next({
        path: '/'
      })
    }else{
      return next()
    }
  }
  return next()
})

export default router
