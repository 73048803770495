import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

function getUser() {
  return Auth.currentAuthenticatedUser().then((user) => {
    if (user && user.signInUserSession) {
      return user
    } else {
      return null
    }
  }).catch(err => {
    console.log(err);
    return null;
  });
}

function signUp(username, password, email) {
  return Auth.signUp({
    username,
    password,
    email,
    attributes: {
      email: email,
    },
  })
    .then(data => {
      AmplifyEventBus.$emit('localUser', data.user);
      if (data.userConfirmed === false) {
        AmplifyEventBus.$emit('authState', 'confirmSignUp');
      } else {
        AmplifyEventBus.$emit('authState', 'signIn');
      }
      return data;
    })
    .catch(err => {
      console.log(err);
      return null;
    });
}

function confirmSignUp(email, code) {
  return Auth.confirmSignUp(email, code).then(data => {
    AmplifyEventBus.$emit('authState', 'signIn')
    return data // 'SUCCESS'
  })
    .catch(err => {
      console.log(err);
      throw err;
    });
}

function resendSignUp(email) {
  return Auth.resendSignUp(email).then(() => {
    return true;
  }).catch(err => {
    console.log(err);
    return err;
  });
}

async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    if (user) {
      AmplifyEventBus.$emit('authState', 'signedIn');
      return true;
    }
    return false;
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
    } else if (err.code === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      console.log(err);
    }
    return false;
  }
}

function signOut() {
  return Auth.signOut()
    .then(data => {
      AmplifyEventBus.$emit('authState', 'signedOut');
      location.reload()
      return data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function changePassword(user, nowpwd, newpwd) {
  return Auth.changePassword(user, nowpwd, newpwd)
    .then(data => {
      console.log(data)
      return null
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function forgotPassword(userId) {
  return Auth.forgotPassword(userId)
    .then(data => {
      console.log(data)
      return null
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function passwordReset(userId, code, password){
  return Auth.forgotPasswordSubmit(userId, code, password)
    .then(data => {
      console.log(data)
      return null
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function updateEmail(user, newEmail){
  return Auth.updateUserAttributes(
    user, {"email": newEmail}
    )
    .then(data => {
      console.log(data)
      return null
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function emailSubmit(code){
  return Auth.verifyCurrentUserAttributeSubmit('email', code)
    .then(data => {
      console.log(data)
      return null
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function resendCode(){
  return Auth.verifyCurrentUserAttribute('email')
    .then(data => {
      console.log(data)
      return null
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function globalSignOut() {
  return Auth.signOut({ global: true })
    .then(data => {
      AmplifyEventBus.$emit('authState', 'signedOut');
      location.reload()
      return data;
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

function getGlobalUser() {
  return Auth.currentUserInfo().then((user) => {
    if (user !== null && user.username !== undefined) {
      return user;
    } else {
      return null;
    }
  }).catch(err => {
    console.log(err);
    return err;
  });
}

export {getUser, signUp, confirmSignUp, resendSignUp, signIn, signOut, changePassword, forgotPassword, passwordReset, updateEmail, emailSubmit, resendCode, globalSignOut, getGlobalUser};