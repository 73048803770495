<template>
  <v-app v-if="signedIn">
    <!-- ナビゲーションバー -->
    <v-navigation-drawer app v-model="drawer" clipped style="background-color:#d7d7d7">
      <v-container>
        <v-list-item style="background-color:#7ca3d7">
          <v-list-item-content>
            <v-list-item-title style="font-size:24px" class="white--text text--darken-2">
              メニュー
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider> <!-- 横線 -->
        <v-list dense nav>
        
          <!-- v-list-group：親リストの項目 -->
          <template v-for="nav_list in nav_lists">
            <v-list-item
               v-if="!nav_list.lists"
               :to="nav_list.link"
               :key="nav_list.name"
               @click="menu_close"
               class="textSize"
            >
              <v-list-item-icon>
                <v-icon>{{ nav_list.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size:16px">
                  {{ nav_list.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-group
              v-else-if="admin"
              no-action
              :key="nav_list.name"
              :prepend-icon="nav_list.icon"
              v-model="nav_list.active"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title style="font-size:16px">{{ nav_list.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              
              <!-- v-list-item：子リストの項目 -->
              <v-list-item
                v-for="list in nav_list.lists"
                :key="list.name"
                :to="list.link"
              >
                <v-list-item-content>
                  <v-list-item-title style="font-size:16px">{{ list.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              
            </v-list-group>
          </template>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <!-- ツールバー -->
    <v-app-bar color="primary" dark app clipped-left>
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="font-size:30px" >KOHA IoT</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-badge
        :content="message"
        :value="message"
        color="red"
        overlap
        offset-x="15"
        offset-y="20"
      >
        <v-btn
          icon
          text
          @click="transitionInformation"
        >
          <v-icon>mdi-email</v-icon>
        </v-btn>
      </v-badge>
      <v-toolbar-items>
        <!-- <v-btn text to="/enterprise">For Enterprise</v-btn> -->
        <v-menu offset-y>
          <template v-slot:activator="{on}">
            <v-btn v-on="on" text style="font-size:16px; text-transform: none">{{ loginname }}<v-icon>mdi-arrow-down-drop-circle</v-icon></v-btn>
          </template>
          <v-list>
            <v-subheader style="font-size:16px">ユーザメニュー</v-subheader>
            <v-divider></v-divider> <!-- 横線 -->
            <v-list-item v-for="support in supports" :key="support.name" :to="support.link">
              <v-list-item-icon>
                <v-icon>{{ support.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size:16px">{{ support.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <amplify-sign-out class="ml-4"></amplify-sign-out>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <!-- メイン部 -->
    <v-main>
      <router-view style="background-color:#eeeeee"/>
    </v-main>
    
    <v-footer color="primary" dark app>
    </v-footer>

  </v-app>
  
  <v-app v-else>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
  
</template>

<style>
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900&display=swap');
  .Noto{
    font_familly:'Noto Sans JP'
  }
</style>

<script>
import {getUser, signOut, getGlobalUser} from '@/js/auth.js'
import HashWork from '@/js/Hash.js'

export default {
  methods:{
    menu_close() {
      this.nav_lists.forEach((nav_list) => (nav_list.active = false));
    },
    logout:function(){
      this.$store.commit('setUser', null);
      this.$store.commit('setSensorGroup', []);
      this.$store.commit('setLoginUser', null);
      this.$store.commit('setDevice', null);
      signOut()
    },
    usermenu:function(ev){
      if(ev === 'ログアウト'){
        this.logout();
      }
    },
    getUserDetail:function(username){
      var apiurl = this.hosturl + "/users/" + username
      
      this.$axios.get(apiurl).then(res => {
        var userdetail = res.data[username]
        if(userdetail['delflg'] === 1){
          alert('無効なユーザです。')
          this.logout()
        }else{
          this.$store.commit('setLoginUser', userdetail);
          if(userdetail['roletype'] === 0){
            this.admin = true;
          } else {
            this.admin = false;
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(username + 'の情報が取得できませんでした。')
      });
    },
    loginchk: function(){
      console.log('ログインチェック');
      getUser().then((user) => {
        console.log('user取得');
        if (user) {
          getGlobalUser().then(result => {
            if(result){
              console.log('user取得成功');
              //idトークン取得
              this.$axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken;
              if(this.admin === null && this.signedIn === false){
                this.getUserDetail(user.username);
              } else if (this.admin === null && this.signedIn === true) {
                return
              }
              this.hashCheck(); //更新チェック
              this.$store.commit('setUser', user);
              this.signedIn = true;
              this.loginname = user.username;
              this.getInformation();
            }else{
              this.signedIn = false;
              if(this.$store.state.user){
                alert('ログイン状態を確認できませんでした。ログイン画面へ戻ります。')
                this.logout()
              }else{
                this.$store.commit('setUser', null);
                this.$store.commit('setSensorGroup', []);
                this.$store.commit('setLoginUser', null);
                this.$store.commit('setDevice', null);
                this.admin = null;
              }
            }
          })
        }else{
          console.log('userなし');
          this.signedIn = false;
          if(this.$store.state.user){
            alert('ログイン状態を確認できませんでした。ログイン画面へ戻ります。')
            this.logout()
          }else{
            this.$store.commit('setUser', null);
            this.$store.commit('setSensorGroup', []);
            this.$store.commit('setLoginUser', null);
            this.$store.commit('setDevice', null);
            this.admin = null;
          }
        }
      })
    },
    hashCheck:function() {
      var url  = ""
      if (process.env.NODE_ENV == 'production'){
        url = 'https://' + document.domain;
      }
      else{
        url = 'http://' + document.domain + ':8080';
      }
      fetch(url + '/index.html'
      )
        .then(res => {
          console.log(url + '/index.html')
          if (res.status !== 200) {
            throw Error('Cannot access server.');
          }
          return res.text();
        })
        .then(html => {
          const hash = HashWork.createHash(html);
          this.judgeHash(hash)
        })
        .catch(err => {
          console.log(err)
          this.signedIn = false;
          alert('更新確認時にエラーが発生しました。ログイン画面に戻ります。')
          this.logout()
        })
    },
    judgeHash:function(hash) {
      if (!this.previousHash) {
        this.previousHash = hash;
        console.log(this.previousHash)
        return;
      }
      if (this.previousHash !== hash) {
        this.previousHash = hash
        console.log('更新検知')
        this.signedIn = false;

        if (process.env.NODE_ENV == 'production'){
          alert('アプリケーションの更新を検知しました。ログイン画面に戻ります。')
          this.logout()
        }
      }else{
        console.log('更新なし')
      }
    },
    getInformation:function(){
      this.informationData = new Array();
      var apiurl = this.hosturl + "/user_information/" + this.loginname +"/?page=app";
      this.$axios.get(apiurl).then(res => {
        this.informationData = res.data;
        if(this.informationData.length > 0){
          this.message = "!";
        }
        else{
          this.message = "";
        }
      })
      .catch((err) => {
        console.log(err);
        alert('おしらせが取得できませんでした。')
      });
    },
    transitionInformation:function(){
      if(this.informationData.length > 0){
        this.finishReading();
      }
      this.$router.push({
        name: 'information',
        params: {
          id: this.informationData
        }
      }, () => {})
    },
    finishReading:function(){
      var apiurl = this.hosturl + "/user_information/" + this.loginname;
      var nowDate = new Date().toISOString().substr(0, 10);
      var nowTime = new Date().toLocaleString().split(' ')[1];
      var information_confirmed_time = nowDate + ' ' + nowTime;
      var param = {
        "information_confirmed_time": information_confirmed_time
      }
      this.$axios.put(apiurl, param).then(res => {
        console.log(res)
        this.getInformation();
      })
    },
  },
  data(){
    return{
      signedIn: false,
      drawer: null,
      supports:[
        {name: 'ユーザセンサ設定', icon: 'mdi-account-settings', link: '/userdev'},
        {name: 'ユーザ設定', icon: 'mdi-key', link: '/userpassword'},
      ],
      admin: null,
      hosturl: process.env.VUE_APP_APIURL,
      nav_lists:[
        {name: 'センサ一覧', icon: 'mdi-clipboard-text', link: '/lastdata'},
        {name: 'センサ詳細', icon: 'mdi-chart-line', link: '/detail'},
        {name: '管理',
         icon: 'mdi-cogs',
         active: false,
         link:'',
         lists:[{name:'ユーザ管理', link:'/users'},
                {name:'センサ管理', link:'/setting'},
                {name:'センサグループ管理', link:'/sensorgroup'}
        ]}
      ],
      loginname: 'ゲスト',
      previousHash:null,
      updateFlg: false,
      message: null,
      informationData: []
    }
  },
  beforeUpdate:function() {
    this.loginchk();
  }
};
</script>
