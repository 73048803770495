<template>
  <v-app>
  <div class="sign-in">
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card class="mx-auto mt-5" max-width="400">
        <v-alert v-model="error" type="error" dismissible>
          {{dialogText}}
        </v-alert>
        <v-card-title class="indigo white--text" style="font-size:30px">
          KOHA IoT
        </v-card-title>
        <v-text-field 
          style="margin:0px 25px" 
          class="mt-5" 
          v-model="username" 
          :rules="userRules" 
          label="ユーザ名" 
          required
        />
        <v-text-field
          v-model="password"
          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          style="margin:0px 25px"
          :type="passwordShow ? 'text' : 'password'"
          :rules="[passwordRules.required]"
          name="password"
          label="パスワード"
          required
          @click:append="passwordShow = !passwordShow"
        />
        <v-row justify="center">
          <v-btn :disabled="!valid" @click="submit" color="primary" class="my-3" style="font-size:16px" >ログイン</v-btn>
        </v-row>
        <v-row>
        <v-spacer></v-spacer>
          <v-btn small plain color="blue" v-on:click="passwordreset()" style="text-transform: none; margin:0px 20px">パスワード再発行</v-btn>
        </v-row>
      </v-card>
    </v-form>
  </div>
  </v-app>
</template>

<script>
import {signIn} from '@/js/auth.js'

export default {
  name: "SignIn",
  data() {
    return {
      valid: false,
      username: '',
      password: '',
      dialogText:null,
      delDialog:false,
      
      error:false,
      passwordShow: false,
    }
  },
  computed: {
    userRules() {
      return [
        v => !!v || 'ユーザ名は必須です'
      ]
    },
    passwordRules() {
      return {
        required: value => !!value || 'パスワードは必須です',
        /*min: v => v.length >= 8 || '8文字以上入力してください。',*/
      }
    },
  },
  methods: {
    showAlert(text) {
      this.dialogText = text;
      this.error = true;
      this.returnTop('smooth');
    },
    submit() {
      this.error = false
      if (this.$refs.form.validate()) {
        // console.log(`SIGN IN username: ${this.username}, password: ${this.password}`);
        signIn(this.username, this.password).then(result => {
            console.log(result); // => resolve!!
            if (!result){
              this.showAlert("ログインに失敗しました。");
            }
        });

        //this.$store.commit('setLoginUser', this.username);
      }
    },
    passwordreset: function(){
      this.$router.replace("/passwordreset");
    },
    returnTop(action) {
      window.scrollTo({
        top: 0,
        behavior: action
      })
    },
  },
}
</script>